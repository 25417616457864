<template>
    <lay-row space="10">

        <lay-col md="12">
            <lay-panel>
                <lay-field title="基本配置">
                  <lay-form :model="comfigdata">
                  <lay-form-item label="图片域名" prop="pictureUrl">
                    <lay-input v-model="comfigdata.pictureUrl"></lay-input>
                  </lay-form-item> 
 
                  <lay-form-item style="text-align: center;">
                    <lay-button type="primary" @click="addConfig">提交</lay-button> 
                  </lay-form-item>
                </lay-form> 

                </lay-field> 
            </lay-panel>
        </lay-col>
        <lay-col md="12">
            <lay-panel>
                <lay-field title="节点分类">
                    <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changePassageCategory" type="primary">新增</lay-button>
          </div>
                    <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
                        v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange"> 

                        <template v-slot:operator="{ row }">
                            <lay-button size="xs" type="primary" @click="updatePassageCategory(row)">编辑</lay-button>
                            <lay-button size="xs" type="primary" @click="delePassageCategory(row)">删除</lay-button>
                        </template>
                        </lay-table> 
                </lay-field>
            </lay-panel>

        </lay-col>
    </lay-row>


    <lay-layer v-model="visible11" :shade="false" :area="['350px', '330px']" :btn="action11" title="添加/修改分类节点">
      <div style="padding: 20px;">
        <lay-form :model="passageCategory" ref="layFormRef11">
          <lay-form-item label="名称" prop="categoryName">
            <lay-input v-model="passageCategory.categoryName" ></lay-input>
          </lay-form-item> 
     
          <lay-form-item label="排序" prop="px">
            <lay-input v-model="passageCategory.px"></lay-input>
          </lay-form-item> 
        </lay-form>
      </div>
    </lay-layer>

</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue' 
import { layer } from '@layui/layer-vue'
import { allPassageCategory, addPassageCategory, delPassageCategory,getConfig,setConfig } from '../../api'
  


//节点分类
const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([
    { title: "序号", key: "id", align: "center" },
    { title: "名称", key: "categoryName", align: "center" },
    { title: "排序", key: "px", align: "center"},
    { title: "添加时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
  ]);
  
  const getAllPassageCategory = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit};
    allPassageCategory(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllPassageCategory();


  const change = (page: any) => {
    getAllPassageCategory();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
 

  
  const passageCategory = reactive({
    id:0,
    categoryName: "",
    px: 0, 
  })
  
  const delePassageCategory = (row: any) => {
    var data={"id":row.id}
  
    delPassageCategory(JSON.stringify(data)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllPassageCategory();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })

}


  
  const updatePassageCategory = (row: any) => {
    passageCategory.id = row.id;
    passageCategory.categoryName = row.categoryName;
    passageCategory.px = row.px;
  
    visible11.value = !visible11.value;
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
   
  
  const changePassageCategory = () => {
    passageCategory.id = 0;
    passageCategory.categoryName = "";
    passageCategory.px = 0;  
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        addPassageCategory(JSON.stringify(passageCategory)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllPassageCategory();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
  
      }
    }
  ])

  //基本配置
  const comfigdata = reactive({
    pictureUrl: "", 
 
  })

  //提交信息
  const addConfig=()=>{

    setConfig(JSON.stringify(comfigdata)).then((res: any) => {
          if (res != "" && res.code == 0) {  
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        }) 
  }


  const fingConfig=()=>{
    getConfig().then((res: any) => {
          if (res != "" && res.code == 0) {  
           
            comfigdata.pictureUrl=res.data.pictureUrl;
         
          }
        }) 
  }

  
  fingConfig();

</script>
  
<style>
 
</style>