<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm">
            <lay-form-item label="节点" prop="username" mode="inline">
              <lay-input v-model="search.key" placeholder="请输入节点名称"></lay-input>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item>
  
          </lay-form>
          <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="addSiteData" type="primary">新增</lay-button>
          </div>
  
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
  
            <template v-slot:operator="{ row }">
              <lay-button size="xs" type="primary" @click="updateSite(row)">编辑</lay-button>
              <lay-button size="xs" type="primary" @click="setDelSite(row)">删除</lay-button>
             
            </template>
          </lay-table>
  
  
  
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['500px', '680px']" :btn="action11" title="添加/修改">
      <div style="padding: 20px;">
 
        <lay-form :model="site" ref="layFormRef11" > 

            <lay-form-item label="站点名称" prop="siteName">
            <lay-input v-model="site.siteName"></lay-input>
          </lay-form-item> 
          <lay-form-item label="网站域名" prop="website">
            <lay-input v-model="site.website"></lay-input>
          </lay-form-item> 
          <lay-form-item label="网站api" prop="websiteApi">
            <lay-input v-model="site.websiteApi"></lay-input>
          </lay-form-item> 
          <lay-form-item label="访问标识" prop="accessId">
            <lay-input v-model="site.accessId"></lay-input>
          </lay-form-item> 

          <lay-form-item label="站点标识" prop="siteId">
            <lay-input v-model="site.siteId"></lay-input>
          </lay-form-item> 

          <lay-form-item label="解密key" prop="key">
            <lay-input v-model="site.key"></lay-input>
          </lay-form-item> 

          <lay-form-item label="解密IV" prop="iv">
            <lay-input v-model="site.iv"></lay-input>
          </lay-form-item>  
          <lay-form-item label="备注" prop="remark">
      <lay-textarea placeholder="请输入描述" v-model="site.remark"></lay-textarea>
    </lay-form-item>
    
        </lay-form>
      </div>
    </lay-layer>
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allSite, delSite, addSite} from '../../api'
  
  const search = reactive({
    key: "",
  })
  
  
  const loading = ref(false);
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([
    { title: "站点名称", key: "siteName", align: "center", width: "130px" },
    { title: "网站域名", key: "website", align: "center" },
    { title: "网站api", key: "websiteApi", align: "center" },
    { title: "访问标识", key: "accessId", align: "center"  , ellipsisTooltip: true},
    { title: "站点标识", key: "siteId", align: "center"},    
    { title: "解密key", key: "key", align: "center"}, 
    { title: "解密IV", key: "iv", align: "center"}, 
    { title: "备注", key: "remark", align: "center" },
    { title: "添加时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", width: "100px", fixed: "right", align: "center" }
  ]);
  
  
  const getAllSite = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allSite(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllSite();
  
  
  const change = (page: any) => {
    getAllSite();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
 
const setDelSite=(res: any)=>{
    var data={'id':res.id}
    delSite(JSON.stringify(data)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllSite();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })

}


  
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllSite();
  }
  
  
  
  const site = reactive({
    siteName: "",
    website: "",
    id: 0,
    websiteApi:'',
    accessId:'',
    siteId:0,
    key:'',
    iv:'',
    remark:'',
  })
  
  
  const updateSite = (row: any) => {
    site.siteName = row.siteName;
    site.remark = row.remark; 
    site.website= row.website; 
    site.websiteApi= row.websiteApi; 
    site.siteId= row.siteId; 
    site.key= row.key; 
    site.iv= row.iv; 
    site.id= row.id; 
    site.accessId= row.accessId;  
    visible11.value = !visible11.value;
  }
  
 

  const layFormRef11 = ref();
  
  const visible11 = ref(false); 
  
  const addSiteData = () => {
    site.siteName = '';
    site.remark = '';
    site.website='';
    site.websiteApi= '';
    site.siteId= 0;
    site.key= '';
    site.iv= '';
    site.id= 0;
    site.accessId= '';
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        addSite(JSON.stringify(site)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllSite();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
  
      }
    }
  ])
  
  
  </script>
  
  <style scoped></style>