<template>
    <lay-panel>
      <lay-form :model="model">
        <lay-form-item label="公告标题" prop="title">
          <lay-input v-model="model.title"></lay-input>
        </lay-form-item>
  
        <lay-form-item label="公告内容" prop="content">
          <textarea
            id="baiduLayeditID"
            name="content"
            style="width: 100%; height: 400px"
          ></textarea>
        </lay-form-item>
  
        <lay-form-item style="text-align: center;">
          <lay-button type="primary" @click="submit">提交</lay-button>
        </lay-form-item>
      </lay-form>
    </lay-panel>
  </template>
  
  <script lang="ts" setup>
  import { reactive, onMounted, onUnmounted } from 'vue';
  import { layer } from '@layui/layer-vue';
  import { addAnnouncement, getAnnouncement } from '../../api';
  
  const model = reactive({
    title: '',
    content: ''
  });
  
  let ue: any = null;
  
  onMounted(() => {
    if (typeof UE === 'undefined') {
      console.error('UEditor script not loaded');
      return;
    }
  
    // 初始化 UEditor
    ue = UE.getEditor('baiduLayeditID');
  
    ue.ready(() => {
      // 在编辑器准备好后设置内容
      ue.setContent(model.content);
    });
  
    // 获取公告数据
    allAnnouncement();
  });
  
  onUnmounted(() => {
    if (ue) {
      ue.destroy(); // 销毁编辑器实例
      ue = null;
    }
  });
  
  const allAnnouncement = async () => {
    try {
      const res = await getAnnouncement();
      if (res && res.code === 0) {
        model.title = res.data.title;
        model.content = res.data.content;
        if (ue) {
          ue.setContent(res.data.content); // 更新编辑器内容
        }
      }
    } catch (error) {
      console.error('Error fetching announcement:', error);
    }
  };
  
  const submit = async () => {
    if (ue) {
      model.content = ue.getContent(); // 获取编辑器内容
      try {
        const res = await addAnnouncement(model);
        if (res && res.code === 0) {
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      } catch (error) {
        console.error('Error submitting announcement:', error);
      }
    } else {
      console.error('UEditor is not initialized');
    }
  };
  </script>
  