<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="app名称" prop="key" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入app名称"></lay-input>
          </lay-form-item>
          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchApp">查询</lay-button>
          </lay-form-item>

        </lay-form>
        <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
        </div>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

          <template #androidVersion="{ row }">
            <lay-button v-if="row.androidVersion" type="normal" @click="downloadApp(row.androidDownloadUrl)">
              {{ row.androidVersion }}
            </lay-button>
          </template>


          <template #iosVersion="{ row }">

            <lay-button v-if="row.iosVersion" type="normal"  @click="downloadApp(row.iosDownloadUrl)">
              {{ row.iosVersion }}
            </lay-button>
          </template>



          <template #iosState="{ row }">
            <space v-if="row.iosState == 1">
              强制更新
            </space>
            <space v-else-if="row.iosState == 0">
              提示更新
            </space>

          </template>


          <template #state1="{ row }">
            <space v-if="row.state == 0">
              正常
            </space>
            <space v-else-if="row.state == 1">
              关闭
            </space>
          </template>


 
 
          <template #androidState="{ row }">
            <space v-if="row.androidState == 1">
              强制更新
            </space>
            <space v-else-if="row.androidState == 0">
              提示更新
            </space>
          </template>

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="updateApp(row)">编辑</lay-button>
            <lay-button size="xs" type="primary" @click="authorizedPlatform(row)">授权平台</lay-button>
            <lay-button size="xs" type="danger" @click="delApp(row)">删除</lay-button>
          </template>

        </lay-table>
      </lay-panel>
    </lay-col>
  </lay-row>
  <lay-layer v-model="visible11" :shade="false" :area="['800px', '630px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="app" ref="layFormRef11" :pane="true">

        <lay-form-item label="名称" prop="name" mode="inline">
          <lay-input v-model="app.name" placeholder="请输入名称"></lay-input>
        </lay-form-item>

        <lay-form-item label="app标识" prop="mark" mode="inline">
          <lay-input v-model="app.mark" placeholder="请输入app标识"></lay-input>
        </lay-form-item>

        <lay-form-item label="图片域名" prop="picture" mode="inline">
          <lay-input v-model="app.picture" placeholder="请输入图片域名"></lay-input>
        </lay-form-item>

        <lay-form-item label="logo" prop="logo" mode="inline">
          <lay-input v-model="app.logo" placeholder="请输入logo地址"></lay-input>
        </lay-form-item>
        <lay-form-item label="站点状态" prop="state"> 
          <lay-radio v-model="app.state" name="state" :value="0" >正常</lay-radio>
          <lay-radio v-model="app.state" name="state" :value="1">关闭</lay-radio> 
        </lay-form-item>

        <lay-form-item label="接口类型" prop="apiType"> 
          <lay-radio v-model="app.apiType" name="apiType" :value="0" >自用</lay-radio>
          <lay-radio v-model="app.apiType" name="apiType" :value="1">出租</lay-radio> 
        </lay-form-item>

        
        <lay-form-item label="key" prop="key" mode="inline">
          <lay-input v-model="app.key" placeholder="请输入加密KEY"></lay-input>
        </lay-form-item>
        <lay-form-item label="iv" prop="iv" mode="inline">
          <lay-input v-model="app.iv" placeholder="请输入加密IV"></lay-input>
        </lay-form-item>

        <lay-form-item label="第三方统计" prop="thirdPartyStatistics">
          <lay-textarea placeholder="请输入第三方统计" v-model="app.thirdPartyStatistics"></lay-textarea>
        </lay-form-item>

        <lay-form-item label="白名单" prop="whitelist">
          <lay-textarea placeholder="请输入白名单" v-model="app.whitelist"></lay-textarea>
        </lay-form-item>

        <lay-line></lay-line>

        <lay-tab v-model="current1">
          <lay-tab-item title="安卓" id="1">
            <div style="padding:20px">


              <lay-form-item label="是否强制" prop="androidState">

                <lay-radio v-model="app.androidState" name="androidState" :value="0">否</lay-radio>
                <lay-radio v-model="app.androidState" name="androidState" :value="1">是</lay-radio>

              </lay-form-item>

              <lay-form-item label="app版本" prop="androidVersion">
                <lay-input v-model="app.androidVersion" placeholder="请输入版本"></lay-input>
              </lay-form-item>


              <lay-form-item label="下载地址" prop="androidDownloadUrl">
                <lay-input v-model="app.androidDownloadUrl" placeholder="请输入下载地址"></lay-input>
              </lay-form-item>

              <lay-form-item label="提示信息" prop="androidTips">
                <lay-textarea placeholder="请输入提示信息" v-model="app.androidTips"></lay-textarea>
              </lay-form-item>


            </div>
          </lay-tab-item>
          <lay-tab-item title="苹果" id="2">
            <div style="padding:20px">  
              <lay-form-item label="是否强制" prop="iosState"> 
                <lay-radio v-model="app.iosState" name="iosState" :value="0">否</lay-radio>
                <lay-radio v-model="app.iosState" name="iosState" :value="1">是</lay-radio>

              </lay-form-item>

              <lay-form-item label="app版本" prop="iosVersion">
                <lay-input v-model="app.iosVersion" placeholder="请输入版本"></lay-input>
              </lay-form-item>


              <lay-form-item label="下载地址" prop="iosDownloadUrl">
                <lay-input v-model="app.iosDownloadUrl" placeholder="请输入下载地址"></lay-input>
              </lay-form-item>

              <lay-form-item label="提示信息" prop="iosTips">
                <lay-textarea placeholder="请输入提示信息" v-model="app.iosTips"></lay-textarea>
              </lay-form-item>

            </div>
          </lay-tab-item>
        </lay-tab>

      </lay-form>
    </div>
  </lay-layer>

  <lay-layer v-model="isauthorizedPlatform" :shade="false" :area="['400px', '800px']" :btn="addAuthorized" title="授权平台">
    <div style="padding: 20px;">

      <lay-checkbox-group v-model="authorizedId">
      <lay-checkbox v-for="platform in platformList" name="like" skin="primary" :value="platform.id" style="margin: 5px;">{{ platform.passageName }}</lay-checkbox>
     
    </lay-checkbox-group>

   </div>
  </lay-layer>   


</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { delPlatform, addPlatform, allPlatform ,allAuthorizedPlatform,addPassagePlatform} from '../../api' 
 
 
const current1 = ref("1")

const search = reactive({
  key: ""
})


const loading = ref(false);
const dataSource = ref([]);
const selectedKeys = ref([]);
const page = reactive({ current: 1, limit: 10, total: 0 });
const columns = ref([
  { title: "logo", key: "logo", align: "center" },
  { title: "名称", key: "name", align: "center" },
  { title: "app标识", key: "mark", align: "center" },
  { title: "站点状态", key: "state", align: "center" , customSlot: "state1"},
  { title: "安卓版本", key: "androidVersion", align: "center", customSlot: "androidVersion" },
  { title: "安卓提示", key: "androidTips", align: "center", ellipsisTooltip: true },
  { title: "安卓状态", key: "androidState", align: "center", customSlot: "androidState" },

  { title: "苹果版本", key: "iosVersion", align: "center", customSlot: "iosVersion" },
  { title: "苹果提示", key: "iosTips", align: "center", ellipsisTooltip: true },
  { title: "苹果状态", key: "iosState", align: "center", customSlot: "iosState" },

  { title: "第三方统计", key: "thirdPartyStatistics", align: "center", ellipsisTooltip: true },
  { title: "图片域名", key: "picture", align: "center" },
  { title: "白名单", key: "whitelist", align: "center", ellipsisTooltip: true },
  { title: "添加时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "160px" }
]);


const getAllAllPlatform = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key, "state": search.state };
  allPlatform(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}
//获取数据
getAllAllPlatform();


const change = (page: any) => {
  getAllAllPlatform();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
const delApp = (row: any) => {
  var data = { "id": row.id };

  delPlatform(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllAllPlatform();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}

//查询接口
const searchApp = () => {
  page.current = 1;
  getAllAllPlatform();
}

const  downloadApp = (url: any) =>{

  window.open(url, '_blank');

}

//获取授权的平台


const isauthorizedPlatform = ref(false);
const platformList= ref([]);
const authorizedId=ref([]);
const mark=ref("");
const  authorizedPlatform = (row: any) =>{ 
  mark.value=row.mark;

   var data={"key":mark.value}

  allAuthorizedPlatform(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) { 
          isauthorizedPlatform.value = true;
          platformList.value=res.data.passageList;
          authorizedId.value=res.data.authorizedId; 
        }
      }) 
}

const addAuthorized = ref([
  {
    text: "确认",
    callback: () => {
      var  data={"key":mark.value,"strId":authorizedId.value}
      addPassagePlatform(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllAllPlatform();
          isauthorizedPlatform.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      isauthorizedPlatform.value = false;
    }
  }
])




const app = reactive({
  id: 0,
  logo: "",
  name: "",
  mark: "",
  state: 0,
  androidVersion: "",
  androidDownloadUrl: "",
  androidTips: "",
  androidState: 0,
  iosVersion: "",
  iosTips: "",
  iosState: 0,
  picture: "",
  logo: "",
  key: "",
  iv: "",
  whitelist: "",
  thirdPartyStatistics:"",
  iosDownloadUrl:"",
  apiType:0,

})


const updateApp = (row: any) => {
  app.id = row.id;
  app.mark = row.mark;
  app.name = row.name;
  app.state = row.state;
  app.androidVersion = row.androidVersion;
  app.androidDownloadUrl = row.androidDownloadUrl;
  app.androidTips = row.androidTips;
  app.androidState = row.androidState;
  app.iosVersion = row.iosVersion;
  app.iosDownloadUrl = row.iosDownloadUrl;
  app.iosTips = row.iosTips;
  app.iosState = row.iosState;
  app.thirdPartyStatistics = row.thirdPartyStatistics;
  app.picture = row.picture;
  app.logo = row.logo;
  app.whitelist = row.whitelist;
  app.key = row.key,
  app.iv = row.iv,
  app.apiType=row.apiType,


  visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const changeVisible11 = () => {
  app.id = 0;
  app.mark = "";
  app.name = "";
  app.state = 0;
  app.androidVersion = "";
  app.androidDownloadUrl = "";
  app.androidTips = "";
  app.androidState = 0;
  app.iosVersion = "";
  app.iosDownloadUrl = "";
  app.iosTips = "";
  app.iosState = 0;
  app.thirdPartyStatistics = "";
  app.picture = "";
  app.logo = "";
  app.whitelist = "";
  app.key = "";
  app.iv = "";
  app.apiType=0,
  visible11.value = !visible11.value;
}

const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addPlatform(JSON.stringify(app)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllAllPlatform();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false;
    }
  }
])




</script>
  
 