import request from "@/utils/request";

export  function  login(data:any) {
    return request({
        url: '/login',
        method: 'post', 
        data,
        headers: {
          'Content-Type': 'application/json'
        }
    })
}


export  function  allMenu() {
  return request({
      url: '/allMenu',
      method: 'post', 
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  getCaptcha() {
  const timeStamp = new Date().getTime();
  return request({
      url: '/api/captcha?t='+timeStamp, 
      method: 'post',   
  })
}


export  function  allAgent(data:any) {
  return request({
      url: '/allAgent',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allGeneralAgent(data:any) {
  return request({
      url: '/allGeneralAgent',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addAgent(data:any) {
  return request({
      url: '/addAgent',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  disableUsers(data:any) {
  return request({
      url: '/disableUsers',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allDomainName(data:any) {
  return request({
      url: '/allDomainName',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  addDomainName(data:any) {
  return request({
      url: '/addDomainName',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


 


export  function  delDomainName(data:any) {
  return request({
      url: '/delDomainName',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  reviseDomainNameState(data:any) {
  return request({
      url: '/reviseDomainNameState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
 
 

export  function  getUser() {
  return request({
      url: '/getUser',
      method: 'post', 
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
 


export  function  changePassword(data:any) {
  return request({
      url: '/changePassword',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
  



export  function  addPlatform(data:any) {
  return request({
      url: '/addPlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  delPlatform(data:any) {
  return request({
      url: '/delPlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}





export  function  allPlatform(data:any) {
  return request({
      url: '/allPlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  domainNamePlatform(data:any) {
  return request({
      url: '/domainNamePlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 



export  function  allPermissions(data:any) {
  return request({
      url: '/allPermissions',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addPermissions(data:any) {
  return request({
      url: '/addPermissions',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  delPermissions(data:any) {
  return request({
      url: '/delPermissions',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allRole(data:any) {
  return request({
      url: '/allRole',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addRole(data:any) {
  return request({
      url: '/addRole',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  delRole(data:any) {
  return request({
      url: '/delRole',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allUser(data:any) {
  return request({
      url: '/allUser',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addUser(data:any) {
  return request({
      url: '/addUser',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 
export  function  modifyUaddress(data:any) {
  return request({
      url: '/modifyUaddress',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  getUserUaddress() {
  return request({
      url: '/getUserUaddress',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


 


export  function  modifyPayPassword(data:any) {
  return request({
      url: '/modifyPayPassword',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 



export  function  setConfig(data:any) {
  return request({
      url: '/addConfig',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  getConfig() {
  return request({
      url: '/getConfig',
      method: 'post',   
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 

 

 
export  function addPassage(data:any) {
  return request({
      url: '/passage/addPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function allPassage(data:any) {
  return request({
      url: '/passage/allPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function delPassage(data:any) {
  return request({
      url: '/passage/delPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function allSite(data:any) {
  return request({
      url: 'sys/site/allSite',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function delSite(data:any) {
  return request({
      url: 'sys/site/delSite',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function addSite(data:any) {
  return request({
      url: 'sys/site/addSite',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function addPassageCategory(data:any) {
  return request({
      url: 'sys/passageCategory/addPassageCategory',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function allPassageCategory(data:any) {
  return request({
      url: 'sys/passageCategory/allPassageCategory',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function delPassageCategory(data:any) {
  return request({
      url: 'sys/passageCategory/delPassageCategory',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function basicConfigPassage(data:any) {
  return request({
      url: '/passage/basicConfigPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function updatePassageState(data:any) {
  return request({
      url: '/passage/updatePassageState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function updateCacheConfigPassage(data:any) {
  return request({
      url: '/passage/updateCacheConfigPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function updatePictureConfigPassage(data:any) {
  return request({
      url: '/passage/updatePictureConfigPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
 

export  function delCacheConfigPassage(data:any) {
  return request({
      url: '/passage/delCacheConfigPassage',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


 

export  function allAuthorizedPlatform(data:any) {
  return request({
      url: 'authorizedPlatform/allAuthorizedPlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function addPassagePlatform(data:any) {
  return request({
      url: 'authorizedPlatform/addPassagePlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  allAdvertise(data:any) {
  return request({
      url: '/advertise/allAdvertise',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addAdvertise(data:any) {
  return request({
      url: '/advertise/addAdvertise',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  reviseAdvertiseState(data:any) {
  return request({
      url: '/advertise/reviseAdvertiseState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  delAdvertise(data:any) {
  return request({
      url: '/advertise/delAdvertise',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addAnnouncement(data:any) {
  return request({
      url: 'announcement/addAnnouncement',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  getAnnouncement() {
  return request({
      url: 'announcement/getAnnouncement',
      method: 'post', 
    
      headers: {
        'Content-Type': 'application/json'
      }
  })
}











 
























